/* App.css */

.appContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.sidebarAndMain {
  display: flex;
  flex-grow: 1;
}

.mainContent {
  flex-grow: 1;
  padding: 1.5rem;
  overflow-y: auto;
    background: linear-gradient(135deg, #f5f7fa 100%, #c3cfe2 100%);
}
