.sidebar { 
    width: 17.5rem;
    flex-shrink: 0;
}

.sidebar .MuiDrawer-paper {
    width: 17.5rem;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0.25rem 0 0.625rem rgba(0, 0, 0, 0.1);
}

.menu-button {
    position: absolute !important;
    top: 4rem;
    left: 1rem;
}

.sidebar-header {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    background: linear-gradient(45deg, #1976d2 30%, #9c27b0 90%) !important;
}

.sidebar-avatar {
    width: 3.5rem;
    height: 3.5rem;
    border: 0.1875rem solid white;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
}

.sidebar-title {
    padding-left: 0.75rem;
    color: white;
    font-weight: bold;
}

.sidebar-list {
    padding: 1rem;
}

.sidebar-list-item {
    border-radius: 0.75rem;
    margin-bottom: 0.5rem;
    transition: background-color 0.3s, transform 0.3s !important;
}

.sidebar-list-item:hover {
    transform: translateY(-0.125rem);
}

.sidebar-list-item.active {
    background-color: rgba(25, 118, 210, 0.1) !important;
}

.sidebar-list-item.active:hover {
    background-color: rgba(25, 118, 210, 0.2);
}

.active-text {
    font-weight: bold !important;
}

.delete-button {
    color: #d32f2f !important;
    opacity: 0.7;
}

.delete-button:hover {
    opacity: 1;
    background-color: rgba(211, 47, 47, 0.1);
}

.sidebar-footer {
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f5f5f5;
}

.add-session-button {
    border-radius: 1.5rem !important;
    padding: 0.75rem !important;
    text-transform: none !important;
    font-weight: bold !important;
    transition: transform 0.3s !important;
}

@media (max-width: 600px) {
    .sidebar,
    .sidebar .MuiDrawer-paper {
        width: 100%;
    }
}
