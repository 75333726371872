.main-content {
  padding-bottom: 8rem;
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #f5f7fa 100%, #c3cfe2 100%);
}

.message-container {
  display: flex;
  margin-bottom: 1rem;
  padding: 0;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(1.25rem); }
  100% { opacity: 1; transform: translateY(0); }
}

.user-message {
  justify-content: flex-end;
}

.assistant-message {
  justify-content: flex-start;
}

.message-box {
  position: relative;
  max-width: 70%;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .message-box {
    max-width: 90%;
  }
}

.user-message-box {
  background-color: #ADD8E6;
  color: #FFFFFF;
}

.assistant-message-box {
  background-color: #ADD8E6;
  color: #333333;
}

.copy-button {
  position: absolute !important;
  right: 0.5rem !important;
  top: 0.5rem !important;
  min-width: auto !important;
  color: #4A90E2 !important;
}

.copy-button:hover {
  background-color: rgba(74, 144, 226, 0.1);
}

.copy-icon {
  font-size: 1.5625rem !important;
}

.thinking-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.markdown-list {
  margin-bottom: 1em;
}

.welcome-container {
  text-align: center;
  padding: 3rem;
  height: auto;
}

.welcome-title {
  font-weight: 700 !important;
  color: #4A90E2 !important;
  margin-bottom: 1rem !important;
}

@media (max-width: 600px) {
  .welcome-title {
    font-size: 2rem !important;
  }
}

@media (min-width: 601px) {
  .welcome-title {
    font-size: 3rem;
  }
}

.welcome-subtitle {
  color: #666;
  margin-bottom: 2rem !important;
}

.section-title {
  color: #4A90E2;
  margin-bottom: 1.5rem !important;
}

.info-card {
  background-color: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(0.625rem) !important;
  box-shadow: 0 0.25rem 1rem 0 rgba(31, 38, 135, 0.2) !important;
  border-radius: 1rem !important;
  overflow: hidden !important;
  cursor: pointer !important;
  transition: transform 0.3s ease !important;
}


.info-card:hover {
  transform: scale(1.05);
}

.info-card-icon {
  font-size: 2.5rem !important;
  color: #4A90E2;
}

.info-card-title {
  margin-top: 1rem;
  color: #333;
}

.info-card-description {
  color: #666;
}

.faq-container {
  margin-top: 3rem !important;
}

.faq-card {
  background-color: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(0.625rem) !important;
  box-shadow: 0 0.25rem 1rem 0 rgba(31, 38, 135, 0.2) !important;
  border-radius: 1rem !important;
  cursor: pointer !important;
  transition: transform 0.3s ease !important;
}

.faq-card:hover {
  transform: scale(1.05);
}

.faq-text {
  color: #333;
}

.copy-success-message {
  position: fixed !important;
  bottom: 1rem !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background-color: #4CAF50 !important;
  color: white !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.2) !important;
  z-index: 1000 !important;
}