.auth-container {
    display: flex;
    height: 100vh;
}

.image-container {
    flex: 1;
    background: linear-gradient(135deg, rgb(245, 247, 250) 100%, rgb(195, 207, 226) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.yoga-image {
    width: 40%;
    border-radius: 10%;
}

.shape {
    position: absolute;
    width: 0.625rem;
    height: 0.625rem;
    background-color: black;
}

.shape.circle {
    border-radius: 50%;
}

.shape.diamond {
    transform: rotate(45deg);
}

.form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;
    background-color: white;
}

.error-alert {
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding: 0.3125rem 0.75rem;
    font-size: 0.875rem;
    text-align: center;
}

.title {
    margin-bottom: 1.5rem !important;
    font-weight: bold !important;
}

.subtitle {
    margin-bottom: 1.5rem !important;
}

.input-field {
    margin-bottom: 1rem !important;
}

.auth-button {
    margin-bottom: 1rem !important;
    background-color: black !important;
    color: white !important;
    text-transform: none !important;
    border-radius: 3.125rem !important;
}

.auth-button:hover {
    background-color: #333 !important;
}

.auth-button.disabled {
    pointer-events: none !important;
    opacity: 0.6;
}

.toggle-text {
    text-align: center;
    margin-bottom: 1rem !important;
}

.toggle-link {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
    font-size: inherit;
}


@media (max-width: 48rem) {
    .auth-container {
        flex-direction: column;
    }

    .image-container,
    .form-container {
        flex: none;
        height: 50vh;
    }

    .yoga-image {
        width: 60%;
    }

    .error-alert {
        width: 80%;
    }
}