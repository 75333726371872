/* input.css */
:root {
    --primary-color: #1976d2;
    --background-color: #FFFFFF;
    --border-color: #E0E0E0;
    --text-color: #333;
    --light-grey: #F9F9F9;
    --medium-grey: #F1F1F1;
  }
.thinking-animation {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  
  .thinking-dot {
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--primary-color);
    border-radius: 50%;
    animation: blink 1.4s linear infinite;
  }
  
  .thinking-dot-0 { animation-delay: 0s; }
  .thinking-dot-1 { animation-delay: 0.2s; }
  .thinking-dot-2 { animation-delay: 0.4s; }
  
  @keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .uploaded-file-box {
    display: flex;
    align-items: center;
    background-color: var(--medium-grey);
    border-radius: 0.5rem;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.25rem;
    max-width: 60%;
  }
  
  .uploaded-file-name {
    margin-left: 0.25rem;
    color: var(--text-color);
  }
  
  .user-message-box {
    display: flex;
    align-items: center;
  }
  
  .user-icon {
    margin-right: 0.25rem;
  }
  
  .user-message {
    color: var(--text-color);
    font-size: 1rem !important;
  }
  
  .input-container {
    display: flex;
    align-items: center !important;
    position: fixed;
    bottom: 0;
    left: 17.1875rem; /* 275px / 16px = 17.1875rem */
    right: 0;
    background-color: var(--background-color);
    padding: 1rem;
    border-top: 1px solid var(--border-color);
    box-shadow: 0 -0.125rem 0.9375rem rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .upload-button {
    margin-right: 0.5rem;
    transition: transform 0.2s;
  }
  
  .upload-button:hover {
    transform: scale(1.1);
  }
  
  .upload-icon {
    font-size: 1.9rem !important;
  }
  
  .uploaded-file-info {
    background-color: var(--light-grey);
    border-radius: 0.75rem;
    padding: 0.5rem 0.75rem;
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }
  
  .uploaded-file-text {
    color: var(--text-color);
  }
  
  .message-input {
    border: 1px solid var(--border-color) !important;
    border-radius: 1.25rem;
    background-color: var(--light-grey);
  }
  
  .message-input .MuiOutlinedInput-root fieldset {
    border: none;
  }
  
  .message-input .MuiOutlinedInput-root:hover fieldset {
    border: none;
  }
  
  .send-button {
    margin-left: 0.5rem !important;
    border-radius: 1.25rem !important;
    background-color: var(--primary-color) !important;
    color: var(--background-color) !important;
    box-shadow: 0 0.25rem 0.375rem rgba(0,0,0,0.1) !important;
    transition: transform 0.3s !important;
    height: 2.5rem !important;
    width: 8%;
  }
  
  .send-button:hover {
    transform: translateY(-0.125rem);
    box-shadow: 0 0.375rem 0.5rem rgba(0,0,0,0.15) ;
  }
  
  .toast-alert {
    width: 100% !important;
  }
  
  /* Responsive styles */
  @media (max-width: 37.5rem) { /* 600px / 16px = 37.5rem */
    .input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem; /* Creates space between elements */
      padding: 0.5rem;
      background-color: #f0f0f0; /* Light background to match chat interfaces */
      border-top: 1px solid #e0e0e0;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  
    .upload-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e5e5e5;
      border: none;
      border-radius: 8px;
      padding: 0.5rem;
      cursor: pointer;
    }
  
    .message-input {
      flex-grow: 1; /* Ensures the input takes up remaining space */
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 8px;
    }
  
    .send-button {
      background-color: #4caf50;
      color: white;
      border: none;
      border-radius: 8px;
      padding: 0.5rem 1rem;
      cursor: pointer;
    }
  
    .uploaded-file-info {
      display: none; /* Hidden on mobile for simplicity */
    }
  
    .uploaded-file-box,
    .user-message-box {
      max-width: 100%;
    }
  }
